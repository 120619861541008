<template>
  <div>
    <el-form ref="queryForm" :model="queryForm" :inline="true" size="small">
      <el-form-item label="姓名" prop="realname">
        <el-input v-model="queryForm.realname"></el-input>
      </el-form-item>
      <el-form-item label="就诊时间" prop="date_range">
        <el-date-picker v-model="queryForm.date_range" type="daterange" range-separator="至" start-placeholder="开始日期"
          end-placeholder="结束日期" value-format="yyyy-MM-dd" style="width: 260px;">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="记录人" prop="adminer">
        <el-input v-model="queryForm.adminer"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="handleSearch" type="primary">查询</el-button>
        <el-button @click="handleResetQueryForm">重置</el-button>
        <el-button @click="handleAdd" type="primary">新增</el-button>
      </el-form-item>
    </el-form>

    <el-table :data="tableData" v-loading="tableLoading" border stripe
      :header-cell-style="{background:'#E4E7ED',color: '#606266'}" size="small" max-height="500px" style="width: 100%;">
      <el-table-column label="#" type="index" width="60"></el-table-column>
      <el-table-column label="姓名" prop="member.realname"></el-table-column>
      <el-table-column label="疫苗名称" prop="type_name.type_name"></el-table-column>
      <el-table-column label="接种时间" prop="time"></el-table-column>
      <el-table-column label="接种方式" prop="way"></el-table-column>
      <el-table-column label="接种地点" prop="location"></el-table-column>
      <el-table-column label="接种针数" prop="num"></el-table-column>
      <el-table-column label="厂家" prop="manufacturers"></el-table-column>
      <el-table-column label="备注" prop="remark"></el-table-column>
      <el-table-column label="操作">
        <template v-slot="{ row }">
          <el-link @click="handleContinue(row)" type="primary" class="margin-r-10">续针</el-link>
          <el-link @click="handleEdit(row)" type="primary" class="margin-r-10">编辑</el-link>
          <el-popconfirm title="确定删除吗？" @confirm="handleDel(row.id)">
            <el-link slot="reference" type="danger">删除</el-link>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <div class="margin-t-10 flex">
      <el-pagination background layout="sizes, prev, pager, next, total, jumper" :total="total"
        :page-sizes="[10, 20, 30]" :current-page.sync="queryForm.page" :page-size.sync="queryForm.page_size"
        @current-change="getVaccines" @size-change="getVaccines">
      </el-pagination>
      <el-button size="small">确定</el-button>
    </div>

    <!-- 新增、编辑的弹窗 -->
    <el-dialog :title="dialogTitle" :visible.sync="showDialog" :close-on-click-modal="false" :before-close="closeDialog"
      width="500px">
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="姓名" prop="member_id">
          <el-select v-model="form.member_id" clearable filterable remote placeholder="输入姓名进行搜索"
            :remote-method="getUserRecords" :loading="userLoadig" class="el-input_inner--rewrite">
            <el-option v-for="item in userRecords" :key="item.id" :label="item.realname" :value="item.id">
              <div class="flex justify-content-s-b">
                <span>{{ item.realname }}</span>
                <span style="color: #8492a6;">{{ item.mobile }}</span>
              </div>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="疫苗种类" prop="name">
          <el-select v-model="form.name" class="el-input_inner--rewrite">
            <el-option v-for="item in vaccineCategories" :key="item.id" :label="item.type_name"
              :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="接种时间" prop="time">
          <el-date-picker v-model="form.time" type="datetime" placeholder="选择日期时间" value-format="yyyy-MM-dd HH:mm:ss"
            class="el-input_inner--rewrite">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="接种方式" prop="way">
          <el-input v-model="form.way"></el-input>
        </el-form-item>
        <el-form-item label="接种地点" prop="location">
          <el-input v-model="form.location"></el-input>
        </el-form-item>
        <el-form-item label="接种针数" prop="num">
          <el-input-number v-model="form.num" :min="1"></el-input-number>
        </el-form-item>
        <el-form-item label="厂家" prop="manufacturers">
          <el-input v-model="form.manufacturers"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input v-model="form.remark"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="closeDialog">取 消</el-button>
        <el-button :loading="submitting" @click="handleSubmit" type="primary">提 交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import {
    getVaccinesAPI,
    saveVaccineAPI,
    delVaccineAPI,
    getAllVaccineCategoriesAPI
  } from '@/api/vaccine.js'
  import {
    getUserRecordDataAPI
  } from '@/api/user-record/record.js'

  export default {
    name: 'Vaccine',
    data() {
      return {
        vaccineCategories: [],

        queryForm: {
          realname: '',
          date_range: '',
          adminer: '',
          page: 1,
          page_size: 10
        },

        dialogTitle: '',
        showDialog: false,
        submitting: false,
        form: {
          member_id: '',
          name: '',
          time: '',
          location: '',
          num: 1,
          way: '',
          remark: '',
          manufacturers: ''
        },
        rules: {
          member_id: [{
            required: true,
            message: '姓名不能为空',
            trigger: 'change'
          }],
          name: [{
            required: true,
            message: '疫苗种类不能为空',
            trigger: 'change'
          }],
          time: [{
            required: true,
            message: '接种时间不能为空',
            trigger: 'change'
          }],
          location: [{
            required: true,
            message: '接种地点不能为空',
            trigger: 'blur'
          }],
          num: [{
            required: true,
            message: '接种针数不能为空',
            trigger: 'change'
          }],
          way: [{
            required: true,
            message: '接种方式不能为空',
            trigger: 'blur'
          }]
        },

        tableData: [],
        total: 0,
        tableLoading: false,
        curRowId: '',

        // 用户档案
        userRecords: [],
        userLoadig: false,
      }
    },
    created() {
      this.getVaccines()
      getAllVaccineCategoriesAPI().then(res => this.vaccineCategories = res)
    },
    methods: {
      handleSearch() {
        this.queryForm.page = 1
        this.getVaccines()
      },
      handleResetQueryForm() {
        this.$refs.queryForm.resetFields()
      },
      handleAdd() {
        this.showDialog = true
        this.dialogTitle = '新增'
      },
      closeDialog() {
        this.$refs.form.resetFields()
        this.showDialog = false
      },
      // 续针
      handleContinue(row) {
        this.showDialog = true
        this.dialogTitle = '新增'
        const copyRow = JSON.parse(JSON.stringify(row))
        // 回显
        this.userRecords = [copyRow.member]
        // 回显
        for (let key in this.form) {
          if (key == 'member_id' || key == 'num' || key == 'time') {
            continue
          }
          this.form[key] = copyRow[key]
        }
        // 返回的 member_id 是字符串会导致回显失败，所以转换下
        this.form.member_id = parseInt(copyRow.member_id)
        this.form.num = parseInt(copyRow.num) + 1
      },
      handleEdit(row) {
        this.showDialog = true
        this.dialogTitle = '编辑'
        this.curRowId = row.id
        const copyRow = JSON.parse(JSON.stringify(row))
        // 回显老人
        this.userRecords = [copyRow.member]
        // 回显
        for (let key in this.form) {
          this.form[key] = copyRow[key]
        }
        // 返回的 member_id 是字符串会导致回显失败，所以转换下
        this.form.member_id = parseInt(this.form.member_id)
      },
      handleDel(id) {
        delVaccineAPI({
          id,
        }).then(res => {
          this.$message.success(res.msg)
          this.getVaccines()
        })
      },
      handleSubmit() {
        this.$refs.form.validate(isValid => {
          if (!isValid) return

          this.submitting = true
          const copyForm = Object.assign({}, this.form)

          if (this.dialogTitle == '编辑') copyForm.id = this.curRowId

          saveVaccineAPI(copyForm).then(res => {
            this.$message.success(res.msg)
            this.closeDialog()
            this.getVaccines()
          }).finally(() => this.submitting = false)
        })
      },

      getVaccines() {
        this.tableLoading = true

        getVaccinesAPI(this.queryForm).then(res => {
          this.tableData = res.data
          this.total = res.total
          this.tableLoading = false
        })
      },
      getUserRecords(keyword) {
        this.userLoadig = true

        setTimeout(() => {
          getUserRecordDataAPI({
            keyword,
            page: 1
          }).then(res => {
            this.userRecords = res.data
            this.userLoadig = false
          })
        })
      }
    }
  }
</script>

<style>
</style>